import React from 'react';
import styled from 'styled-components';
import { Heading, Text, IconButton, Button } from '@atoms';
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { useStaticQuery, graphql, Link } from 'gatsby';

export const NewsConversionPanel = (component) => {
  const newsroomListingConversionPanelData = useStaticQuery(graphql`
    query newsroomListingConversionPanelQuery {
      contentfulComponentConversionPanel(type: {eq: "newsroom-listing"}) {
        id
        textAlign
        alignContent
        background
        callsToAction {
          id
          internalLink
          label
          link
          type
          variant
        }
        heading
        headingSize
        kicker
        noTopPadding
        noBottomPadding
        subheading {
          raw
        }
      }
    }
  `)

  console.log('nr cp', newsroomListingConversionPanelData);

  const { kicker, heading, headingSize, subheading, callsToAction } = newsroomListingConversionPanelData?.contentfulComponentConversionPanel;

  const dummy = {
    featuredImage: { file: { url: '/tmp/news-listing-hero.png' } },
    // kicker: 'optional kicker lies here',
    // heading: 'Want to see Workgrid in Action?',
    // subheading: 'Option body copy can go here as well if needed',
    // ctaLabel: 'Secondary Button',
    // ctaLink: '/demo',
    testimonial: {
      quote: '“Lorem ipsum dolor sit amet consectetur adipiscing elit”',
      name: 'Caroline Marks',
      job: 'Himalayas International',
      photo: { file: { url: '/tmp/news-conversion-author.png' } },
    }
  };

  const { testimonial } = dummy;

  return (
    <NewsConversionPanelContainer
      data-comp={NewsConversionPanel.displayName}
    >
      <div className="news-conversion--wrapper">
        <div className="news-conversion--main">
          {kicker && <Text className="news-conversion--kicker">{kicker}</Text>}
          {heading && <Heading headingSize={headingSize} className="news-conversion--heading">{heading}</Heading>}
          {subheading?.raw && <Text textVariant='light' className='news-conversion--subheading'>{documentToReactComponents(JSON.parse(subheading?.raw))}</Text>}
          <div className="news-conversion--buttons">
            <IconButton ctaVariant='primary' ctaLink='/'>Schedule a Demo</IconButton>
            {callsToAction[0]?.link && <Button ctaVariant='quaternary' ctaLink={callsToAction[0].link} {...callsToAction[0]}>{callsToAction[0].label}</Button>}
          </div>
        </div>
        <div className="news-conversion--testimonial">
          <Text className="news-conversion--quote">{testimonial.quote}</Text>
          <div className="news-conversion--author">
            <img src={testimonial.photo.file.url} alt={testimonial?.name || "author image"} />
            <span><b>{testimonial.name}</b>, {testimonial.job}</span>
          </div>
        </div>
      </div>
    </NewsConversionPanelContainer>
  );
};

NewsConversionPanel.displayName = 'NewsConversionPanel';

const NewsConversionPanelContainer = styled.div`
  font-family: Roboto;
  position: relative;
  width: 100%;
  background: #E4E9F1;
  overflow: hidden;

  .news-conversion--wrapper {
    max-width: 1290px;
    padding: 96px 60px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: 992px) {
      max-width: 100%;
      flex-direction: column;
    }

    @media (max-width: 600px) {
      padding: 64px 35px;
    }
  }

  .news-conversion--main {
    max-width: 725px;

    @media (max-width: 992px) {
      text-align: center;
    }

    .news-conversion--kicker {
      font-weight: 900;
      font-size: 14px;
      line-height: 24px;
      letter-spacing: 2px;
      text-transform: uppercase;
      color: #0022B7;
      margin-bottom: 16px;
    }

    .news-conversion--subheading {
      font-weight: 400;
      font-size: 22px;
      line-height: 30px;
      color: #475467;
      margin-top: 16px;
    }

    .news-conversion--buttons {
      margin: 8px -8px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-wrap: wrap;

      @media (max-width: 992px) {
        justify-content: center;
      }

      & > a {
        display: block;
        margin: 8px;
      }
    }
  }

  .news-conversion--testimonial {
    max-width: 370px;
    padding: 24px 0 24px 48px;

    @media (max-width: 992px) {
      max-width: 100%;
      padding: 32px 24px 6px;
    }

    .news-conversion--quote {
      font-weight: 700;
      font-size: 22px;
      line-height: 30px;
      color: #101828;

      @media (max-width: 992px) {
        text-align: center;
      }
    }

    .news-conversion--author {
      display: flex;
      align-items: center;
      margin: 12px 0 0;

      @media (max-width: 992px) {
        justify-content: center;
      }

      img {
        width: 32px;
        height: 32px;
        margin-right: 12px;
      }

      span {
        font-size: 13px;
        line-height: 20px;
        color: #475467;
      }
    }
  }

`
